<template>
  <div class="repositories-popup">
    <div class="close-btn" @click="goBack">
      &#x2715;
    </div>
    <div class="popup-content">
      <!-- Search Bar -->
      <div class="search-container">
        <input type="text" v-model="search" placeholder="Search..." @input="searchData">
        <img
          src="@/assets/icons/search.svg"
          width="16"
          height="16"
          alt="search"
          class="search-icon"
        />
      </div>

      <!-- List of Folders -->
      <div class="folders">
        <div
            v-for="(repository, index) in fileRepositories"
            :key="index"
            class="folder"
            @click="selectRepository(repository)"
            :title="repository.name"
        >
          <img class="folder_icon" src="@/assets/icons/folder-color-icon.svg" alt="folder">
          <div class="folder_name">{{ repository.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: {
    descriptions: {
      type: Object,
      default() {
        return {};
      }
    },
    backPath: String,
  },
  data() {
    return {
      search: '',
      fileRepositories: []
    };
  },
  computed: {
    ...mapGetters('fileRepositories', [
      'totalRecords'
    ]),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('fileRepositories', [
      'handleGetFileRepositoriesByQuery',
    ]),
    async initialize() {
      let limit = 20;
      let offset = 0;

      let totalRecords = 0;
      let fileRepositories = [];

      do {
        let query = {
          queryRequest: {
            must: [],
            sortBy: this.sortBy,
            skip: offset,
            limit: limit,
          }
        };

        const data = await this.handleGetFileRepositoriesByQuery(query);
        totalRecords = this.totalRecords;
        fileRepositories = fileRepositories.concat(data);

        offset += limit;

      } while (fileRepositories.length < totalRecords);


      this.fileRepositories = fileRepositories;
      this.ready = true;
    },
    async searchData(e) {
      if (e.target.value.length > 0) {
        this.fileRepositories = await this.handleGetFileRepositoriesByQuery({
          queryRequest: {
            must: [],
            multiFields: [
              {
                queryFields: [],
                queryValue: e.target.value,
              },
            ],
            sortBy: this.sortBy,
            skip: 0,
            limit: 20,
          }
        });
      } else {
        await this.initialize();
      }
    },
    selectRepository(repository) {
      this.$emit('setCurrentPage', {page: 'files', id: repository.id, name: repository.name});
    },
    goBack() {
      this.$emit('setCurrentPage', { page: 'selectIcon' });
    }
  },
};
</script>

<style scoped lang="scss">
.repositories-popup {
  width: 690px;
  max-height: 380px;
  min-height: 380px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  padding: 20px 0;

  .close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 34px;
    height: 34px;
    top: -17px;
    right: -17px;
    z-index: 55;
    border-radius: 50%;
    border: 1px solid #b2b6c2;
    background-color: #fff;
    cursor: pointer;
  }

  .popup-content {
    position: relative;
    overflow-y: auto;
    width: 100%;
    height: 100%;
  }
}

.search-container {
  width: 100%;
  padding: 10px;
  position: relative;

  .search-icon {
    position: absolute;
    top: 13px;
    right: 20px;
  }

  > input {
    width: inherit;
    border: 1px solid #D8DBE0;
    height: 34px;
  }

  > input:focus {
    outline: none;
  }
}

input:focus {
  border: 1px solid #D8DBE0;
}

.folders {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 310px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D8DBE0;
    border-radius: 4px;
  }
}

.folder {
  padding: 5px 3px;
  cursor: pointer;
  width: 90px;
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  margin: 10px;

  .folder_icon {
    height: 45px;
  }

  .folder_name {
    width: inherit;
    height: 21px;
    font-size: 14px;
    color: #3C4B64;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    padding: 0 3px;
  }
}

.folder:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
}

.folder {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}


</style>
