<template>
  <div class="color-picker-container">
    <button
      class="btn mr-2 wizard-action-btn position-relative zq--custom-button btn-outline-dark"
      type="button"
      @click="togglePicker"
    >
      Choose color
    </button>
    <div class="picker-wrapper">
      <button
        v-if="isPickerVisible"
        class="close-button"
        type="button"
        @click="togglePicker"
      >
        &#x2715;
      </button>
      <sketch-picker
        class="mr-2"
        v-if="isPickerVisible"
        v-model="selectedColor"
        @input="updateColor"
      />
    </div>
    <div class="color-preview" :style="{ backgroundColor: selectedColor }" @click="togglePicker"></div>
  </div>
</template>

<script>
import { Sketch } from 'vue-color'
export default {
  name: 'ColorPicker',
  components: {
    'sketch-picker': Sketch,
  },
  props: {
    selectedPropColor: String
  },
  data() {
    return {
      selectedColor: this.selectedPropColor || '#FFF', // Default color
      isPickerVisible: false,
    }
  },
  methods: {
    selectColor(color) {
      this.$emit('colorSelected', color);
    },
    togglePicker() {
      this.isPickerVisible = !this.isPickerVisible;
    },
    updateColor(color) {
      this.selectedColor = color.hex;
      this.selectColor(color.hex)
    },
  }
};
</script>

<style scoped lang="scss">
.color-picker-container {
  position: relative;
  display: flex;
  align-items: start;
}

.color-preview {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}

.select-color-button {
  margin-top: 5px;
  cursor: pointer;
}

.picker-wrapper {
  position: relative;

  .close-button {
    position: absolute;
    top: -180px;
    right: -280px;
    z-index: 1000;
    border-radius: 50%;
    border: 1px solid #b2b6c2;
    background-color: #fff;
  }

  .vc-sketch {
    position: absolute;
    top: -170px;
    left: 45px;
    z-index: 15;
  }
}
</style>