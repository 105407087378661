<template>
  <ClFormRow
    :label="label"
    :toolTipText="tooltip"
    tooltipPosition="label"
    col-label="10"
    sm-label="10"
    md-content="12"
    xl-content="12"
  >
    <template #content>
      <div class="zq--metadata">
        <template v-for="(field, index) in meta">
          <CRow form :key="index">
            <CCol :sm="colSm" :md="colMd" :lg="colLg" :xl="colXl" :class="paddingRight">
              <ClRowInput
                type="text"
                name="metakey"
                v-model="field.key"
                :placeholder="placeholders.key"
                :disabled="disabled"
              />
            </CCol>
            <CCol :sm="colSm" :md="colMd" :lg="colLg" :xl="colXl" :class="paddingRight">
              <ClRowInput
                type="text"
                name="metavalue"
                v-model="field.val"
                :placeholder="placeholders.value"
                :disabled="disabled"
              />
            </CCol>

            <CCol
              sm="4"
              md="3"
              lg="2"
              class="zq--metadata--actions"
              v-show="!disabled"
            >
              <CButton
                type="button"
                :color="setBtnColor('danger')"
                size="sm"
                class="zq--small-button"
                @click="deleteField(index)"
              >
                <CIcon name="cil-minus" />
              </CButton>
              <CButton
                type="button"
                :color="setBtnColor('success')"
                class="zq--small-button ml-2"
                size="sm"
                :disabled="!field.val || !field.key"
                @click="addField(index)"
                v-if="meta.length - 1 === index"
              >
                <CIcon name="cil-plus" />
              </CButton>
            </CCol>
          </CRow>
        </template>
      </div>
    </template>
  </ClFormRow>
</template>

<script>
import ClRowInput from '@/components/forms/ClRowInput';
import { isEqual } from 'lodash';
export default {
  name: 'CIMetaDataField',
  components: {
    ClRowInput,
  },
  props: {
    value: [Object, Array],
    disabled: Boolean,
    headers: Boolean,
    isValid: Boolean,
    invalidMessage: String,
    label: String,
    tooltip: String,
    colSm: {
      type: String,
      default: '4'
    },
    colMd: {
      type: String,
      default: '4'
    },
    colLg: {
      type: String,
      default: '5'
    },
    colXl: {
      type: String,
      default: '3'
    },
    paddingRight: {
      type: String,
      default: 'pr-0'
    }
  },
  data() {
    return {
      meta: [],
      placeholders: { key: 'Metadata key', value: 'Metadata value' },
    };
  },
  created() {
    this.initialize();
  },
  watch: {
    meta: {
      deep: true,
      handler() {
        const obj = {};
        this.meta.forEach((x) => {
          if (x.key) {
            obj[x.key] = x.val;
          }
        });
        this.$emit('input', Object.keys(obj).length ? obj : null);
      },
    },
    value: {
      deep: true,
      handler(n, o) {
        if (!isEqual(n, o)) {
          this.initialize();
        }
      },
    },
  },
  methods: {
    blankMeta() {
      this.meta = [{ key: '', val: '' }];
    },
    initialize() {
      if (this.value && Object.keys(this.value).length > 0) {
        const obj = [];
        Object.keys(this.value).forEach((x) => {
          obj.push({ key: x, val: this.value[x] });
        });
        this.meta = obj;
      } else {
        this.blankMeta();
      }
      this.checkHeaders();
    },
    addField: function (index) {
      this.meta.push({ key: '', value: '' });
    },
    deleteField: function (index) {
      if (this.meta.length === 1) {
        this.blankMeta();
      }
      if (this.meta.length > 1) {
        this.meta.splice(index, 1);
      }
    },
    checkHeaders() {
      if (this.headers || this.label === 'Headers') {
        this.placeholders = { key: 'Header key', value: 'Header value' }
      }
    },
    setBtnColor(color) {
      switch (this.theme) {
        case 'main': {
          return '';
        }
        case 'default': {
          return color;
        }
        default: {
          return '';
        }
      }
    },
  },
};
</script>
