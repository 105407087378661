<template>
  <div class="settings-files">
    <!--  Header    -->
<!--    <LocalBreadcrumb-->
<!--      :isPageNumber="false"-->
<!--      :items="breadcrumbItems"-->
<!--      @setCurrentPage="setCurrentPage"-->
<!--    />-->
    <!-- Table  Header  -->
    <div class="d-flex justify-content-end mb-2">
      <ClNavButton :label="$t('buttons.goBack')" @handler="goBack"/>
    </div>
    <!--  Table  -->
    <CRow class="mr-0">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody>
            <CDataTable
              id="filesTable"
              ref="filesTable"
              class="zq--table"
              :items="fileObjects"
              :fields="fields"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="!ready"
              @pagination-change="itemsPerPageSelect"
              @page-change="paginationChange"
            >
              <template #loading>
                <TableLoader/>
              </template>
              <!--       ID       -->
              <template #id="{ item }">
                <td @click="addFile(item)">
                  <CLink>
                    {{ item.id }}
                  </CLink>
                </td>
              </template>
              <!--       icon       -->
              <template #icon="{ item }">
                <td>
                  <img
                    v-if="['image/png', 'image/jpeg', 'image/svg', 'image/svg+xml'].includes(item.mimeType)"
                    :src=item.uri
                    alt=""
                    class="table-icon"
                  >
                </td>
              </template>
              <!--    Created      -->
              <template #created="{item}">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
            </CDataTable>
            <!--     Table Footer       -->
            <TableFooter
              :page="page"
              @updatePagenation="paginationChange"
              :pages="pages"
              :total="resultCount"
              :itemsPerPage="itemsPerPage"
              :disabled="!ready"
              @updateItemPerPage="itemsPerPageSelect"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
// import LocalBreadcrumb from '@/shared/components/LocalBreadcrumb';
import TableFooter from '@/components/table/Footer';
import TableLoader from "@/components/table/Loader";
import {pageConfig} from '@/config';
import {dateFormate} from '@/utils/dateFormate';
import ClNavButton from "@/shared/components/formComponents/ClNavButton";

export default {
  components: {
    TableFooter,
    TableLoader,
    // LocalBreadcrumb,
    ClNavButton
  },
  props: {
    fileData: Object,
    backPath: String,
    breadcrumbItems: Array,
  },
  data() {
    return {
      ready: false,
      page: pageConfig.page,
      itemsPerPage: pageConfig.itemsPerPage,
      fields: [
        "id", "created", "repositoryId", "name", "icon", "mimeType", "path", "parentFolderPath", "uri"
      ],
      sortBy: [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('files', [
      'fileObjects',
      'pages',
      'loading',
      'resultCount',
      'success',
      'message'
    ]),
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjectsByQuery']),
    async initialize() {
      this.query = {
        idArray: [],
        queryRequest: {
          must: [
            {
              'queryField': 'repositoryId',
              'queryValues': [this.fileData.id],
            }
          ],
          sortBy: this.sortBy,
          skip: 0,
          limit: this.itemsPerPage,
        }
      };
      await this.handleGetFileObjectsByQuery(this.query);
      this.ready = true;
    },
    dateFormate(val) {
      return dateFormate(val);
    },
    goBack() {
      this.$emit('setCurrentPage', {page: this.backPath});
    },
    setCurrentPage(e) {
      this.$emit('setCurrentPage', {page: e});
    },
    //  TABLES
    itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      this.handleGetFileObjectsByQuery({
        idArray: [],
        queryRequest: {
          must: [
            {
              'queryField': 'repositoryId',
              'queryValues': [this.fileData.id],
            }
          ],
          sortBy: this.sortBy,
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }
      });
    },
    async paginationChange(val) {
      this.page = val;

      await this.handleGetFileObjectsByQuery({
        idArray: [],
        queryRequest: {
          must: [
            {
              'queryField': 'repositoryId',
              'queryValues': [this.fileData.id],
            }
          ],
          sortBy: this.sortBy,
          skip: (this.page - 1) * this.itemsPerPage,
          limit: this.itemsPerPage,
        }
      });
    },
    async addFile(item) {
      if (item.mimeType === 'inode/directory') {
        const localFolder = item.path.replace('/' + this.fileData.name, '')

        await this.handleGetFileObjectsByQuery({
          idArray: [],
          queryRequest: {
            must: [
              {
                queryField: 'repositoryId',
                queryValues: [this.fileData.id],
              },
              {
                queryField: 'parentFolderPath',
                queryValues: [localFolder],
              }
            ],
            sortBy: this.sortBy,
            skip: (this.page - 1) * this.itemsPerPage,
            limit: this.itemsPerPage,
          }
        });
      } else {
        this.$emit('addFile', {id: item.id, uri: item.uri});
      }
    }
  },
  watch: {
    fileData(val) {
      console.log(val)
    }
  }
}
</script>

<style scoped lang="scss">
.settings-files {
  .table-icon {
    max-width: 110px;
    max-height: 40px;
  }
}
</style>