<template>
  <div class="flex-column w-50">
    <div class="pb-2 settings-title">{{ texts.createPage.messagesStep.celebrationSectionTitle }}</div>
    <div class="flex-column text-block mb-2">
      <span class="tag-label-text">{{ texts.createPage.messagesStep.celebrationMessageHeader }}</span>
      <ClEditor
        ref="celebrationMessage"
        @change="onEditorCelebrationMessageChange"
        :is-toolbar="true"
        :value-prop="localMessageSettings.celebrationMessage"
        :placeholder="'celebrationMessage'"
        :disabled="false"
        :name="'celebrationMessage'"
        :editorOptionsProps="editorOptions"
        :editorHeight="50"
      />
    </div>
    <div class="flex-column text-block">
      <span class="tag-label-text">{{ texts.createPage.messagesStep.celebrationMessageBody }}</span>
      <ClEditor
        ref="celebrationText"
        @change="onEditorCelebrationTextChange"
        :is-toolbar="true"
        :value-prop="localMessageSettings.celebrationText"
        :placeholder="'celebrationText'"
        :disabled="false"
        :name="'celebrationText'"
        :editorOptionsProps="editorOptions"
        :editorHeight="50"
      />
    </div>

    <CIIconField
      class="w-100"
      v-model="localMessageSettings.celebrationImage"
      :key="'celebrationImage'"
      :label="texts.createPage.messagesStep.celebrationImage"
      @deleteFile="handleDeleteCelebrationImageFile"
      :isColumn="true"
      :isPopup="true"
      :descriptionKey="'celebrationImage'"
      :descriptions="descriptions.messages"
    />
    <div class="flex-column w-50">
      <div class="zq--form-row--label">
        <span class="tag-label-text">{{ texts.createPage.messagesStep.celebrationAnimation }}</span>
      </div>
      <div class="d-flex align-items-center">
        <CSwitch
          color="success"
          shape="pill"
          :value="localMessageSettings.isCelebrationAnimation"
          :checked="localMessageSettings.isCelebrationAnimation"
          class="zq--switch"
          :labelOn="'on'"
          :labelOff="'off'"
          :disabled="false"
          @update:checked="celebrationAnimationUpdate"
        />
        <IconWithTooltip class="ml-2" :text="descriptions.messages.celebrationAnimation"/>
      </div>
    </div>
    <div class="dividing-line mt-4 mb-4"></div>
    <div class="mb-2 mt-3 settings-title">{{ texts.createPage.messagesStep.loseSectionTitle }}</div>

    <div class="flex-column text-block mb-2">
      <span class="tag-label-text">{{ texts.createPage.messagesStep.loseMessageHeader }}</span>
      <ClEditor
        ref="celebrationMessage"
        @change="onEditorSorryMessageChange"
        :is-toolbar="true"
        :value-prop="localMessageSettings.sorryMessage"
        :placeholder="'sorryMessage'"
        :disabled="false"
        :name="'sorryMessage'"
        :editorOptionsProps="editorOptions"
        :editorHeight="50"
      />
    </div>
    <div class="flex-column text-block">
      <span class="tag-label-text">{{ texts.createPage.messagesStep.loseMessageBody }}</span>
      <ClEditor
        ref="sorryText"
        @change="onEditorSorryTextChange"
        :is-toolbar="true"
        :value-prop="localMessageSettings.sorryText"
        :placeholder="'sorryText'"
        :disabled="false"
        :name="'sorryText'"
        :editorOptionsProps="editorOptions"
        :editorHeight="50"
      />
    </div>

    <CIIconField
      class="w-100"
      v-model="localMessageSettings.sorryImage"
      :key="'sorryImage'"
      :label="texts.createPage.messagesStep.loseImage"
      @deleteFile="handleDeleteSorryImageFile"
      :isColumn="true"
      :isPopup="true"
      :descriptionKey="'loseImage'"
      :descriptions="descriptions.messages"
    />

    <div class="flex-column w-25">
      <div class="zq--form-row--label">
        <span class="tag-label-text">{{ texts.createPage.messagesStep.loseAnimation }}</span>
      </div>
      <div class="d-flex align-items-center">
        <CSwitch
          color="success"
          shape="pill"
          :value="localMessageSettings.isSorryAnimation"
          :checked="localMessageSettings.isSorryAnimation"
          class="zq--switch"
          :labelOn="'on'"
          :labelOff="'off'"
          :disabled="false"
          @update:checked="sorryAnimationUpdate"
        />
        <IconWithTooltip class="ml-2" :text="descriptions.messages.loseAnimation"/>
      </div>
    </div>

  </div>
</template>

<script>
import { Quill } from 'vue2-editor';
import CITextArea from '@/shared/components/formComponents/CITextArea.vue';
import ClEditor from '@/shared/components/formComponents/ClEditor.vue';
import CIIconField from '@/shared/components/CIIconField/CIIconField.vue';
import IconWithTooltip from '@/shared/UI/IconWithTooltip.vue';

function getFontName(font) {
  return font.toLowerCase().replace(/\s/g, "-");
}

// Specify Quill fonts
const fontList = ['Arial', 'Courier', 'Garamond', 'Tahoma', 'Times New Roman', 'Verdana'];
const fontNames = fontList.map(font => getFontName(font));
const fonts = Quill.import('formats/font');
fonts.whitelist = fontNames;
Quill.register(fonts, true);

// Add fonts to CSS style
let fontStyles = '';
fontList.forEach(function(font) {
  let fontName = getFontName(font);
  fontStyles += ".ql-snow .ql-picker.ql-font .ql-picker-label[data-value=" + fontName + "]::before, .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=" + fontName + "]::before {" +
      "content: '" + font + "';" +
      "font-family: '" + font + "', sans-serif;" +
      "}" +
      ".ql-font-" + fontName + "{" +
      " font-family: '" + font + "', sans-serif;" +
      "}";
});

const node = document.createElement('style');
node.innerHTML = fontStyles;
document.body.appendChild(node);

export default {
  name: 'CelebrationMessages',
  components: { IconWithTooltip, CIIconField, ClEditor, CITextArea },
  props: {
    messageSettings: Object,
    texts: Object,
    descriptions: Object,
  },
  data() {
    return {
      editorOptions: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline'],
              [
                { align: '' },
                { align: 'center' },
                { align: 'right' },
                { align: 'justify' }
              ],
              [{ color: [] }],
              [{ 'font': fonts.whitelist }],
              [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            ],
          },
        },
      },
      localMessageSettings: this.messageSettings,
    }
  },
  watch: {
    localMessageSettings: {
      deep: true,
      handler(val) {
        this.$emit('updateMessageSettings', val)
      }
    }
  },
  methods: {
    onEditorCelebrationMessageChange(value) {
      this.localMessageSettings.celebrationMessage = value;
    },
    onEditorCelebrationTextChange(value) {
      this.localMessageSettings.celebrationText = value;
    },
    handleDeleteCelebrationImageFile() {
      this.localMessageSettings.celebrationImage = ''
    },
    celebrationAnimationUpdate(val) {
      this.localMessageSettings.isCelebrationAnimation = val;
    },
    onEditorSorryMessageChange(value) {
      this.localMessageSettings.sorryMessage = value;
    },
    onEditorSorryTextChange(value) {
      this.localMessageSettings.sorryText = value;
    },
    handleDeleteSorryImageFile() {
      this.localMessageSettings.sorryImage = ''
    },
    sorryAnimationUpdate(val) {
      this.localMessageSettings.isSorryAnimation = val;
    },
  }
};
</script>

<style scoped lang="scss">
.settings-title {
  font-size: 18px;
  font-weight: 400;
  color: #3C4B64;
}

.dividing-line {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid #D8DBE0;
}
</style>
