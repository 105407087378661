<template>
  <div class="instant-win-settings">
    <!--  Header    -->
    <CRow class="p-0 m-0">
      <CCol col="12" class="d-flex p-0">
        <h3 class="mb-3 mr-1 bread-content">{{ texts.createPage.settings.settingsTitle }}</h3>
        <IconWithTooltip class="zq--header-tooltip" :text="descriptions.settings.settingsTitle"/>
      </CCol>
    </CRow>
    <ZqInstantWinTypeSpinner
      v-if="isScratchCardNotAllowed"
      :label="getLabel('instantWinType')"
      v-model="instantWinType"
      :tooltip="getTooltipByName('instantWinType')"
    />
    <ZqInstantWinType
      v-else
      :label="getLabel('instantWinType')"
      v-model="instantWinType"
      :tooltip="getTooltipByName('instantWinType')"
    />
    <div class="d-flex align-items-center w-100 name-tag-wrapper">
      <CIInputCol
        class="col-5"
        :label="getLabel('name')"
        :tooltip="getTooltipByName('name')"
        v-model="name"
        :placeholder="'name'"
        :required="true"
        :name="'name'"
      />
      <CITagField
        class="col-5 pl-0 instant-win-tag"
        :is-column="true"
        v-model="tags"
        :type="model"
        :tooltip="getTooltipByName('tags')"
      />
    </div>
    <div class="d-flex align-items-center w-100 text-area-fields-wrapper">
      <CITextArea
        class="col-5"
        :is-column="true"
        :label="getLabel('description')"
        :tooltip="getTooltipByName('description')"
        v-model="description"
        :placeholder="'description'"
        :name="description"
        :isToolbar="true"
      />
      <CITextArea
        class="col-5"
        :is-column="true"
        :label="getLabel('termsAndConditions')"
        :tooltip="getTooltipByName('termsAndConditions')"
        v-model="termsAndConditions"
        :placeholder="'description'"
        :name="termsAndConditions"
        :isToolbar="true"
      />
    </div>
    <div class="d-flex align-items-center">
      <CIMetaDataField
        v-model="metadata"
        :label="getLabel('metadata')"
        :tooltip="getTooltipByName('metadata')"
        :placeholder="'placeholder'"
        :colSm="'5'"
        :colMd="'5'"
        :colLg="'5'"
        :colXl="'5'"
        :paddingRight="'pr-4'"
      />
    </div>
    <div class="d-flex align-items-center">
<!--      <CICustomFields-->
<!--        :type="'InstantWin'"-->
<!--        v-model="customFields"-->
<!--        :sortBy="'name'"-->
<!--      />-->
    </div>
  </div>
</template>

<script>
import ZqInstantWinType from '@/shared/components/ZqInstantWinType.vue';
import CIInputCol from '@/shared/components/formComponents/CIInputCol.vue';
import CITagField from '@/shared/components/CITagField.vue';
import { isEqual, startCase } from 'lodash';
import CITextArea from '@/shared/components/formComponents/CITextArea.vue';
import CIMetaDataField from '@/shared/components/CIMetaDataField.vue';
import IconWithTooltip from '@/shared/UI/IconWithTooltip.vue';
import ZqInstantWinTypeSpinner from '@/shared/components/supportModels/instantWins/ZqInstantWinTypeSpinner.vue';

export default {
  name: 'CreateInstantWinSettings',
  components: {
    ZqInstantWinTypeSpinner,
    IconWithTooltip,
    CIMetaDataField,
    CITextArea,
    CITagField,
    CIInputCol,
    ZqInstantWinType
  },
  props: {
    list: [Array],
    descriptions: Object,
    texts: Object
  },
  data() {
    return {
      formList: null,
      obj: null,
      requiredText: 'Required',
      model: '',
      instantWinType: 1,
      name: '',
      description: '',
      termsAndConditions: '',
      metadata: null,
      customFields: null,
      tags: []
    }
  },
  created() {
    this.model = this.list[0].model;

    if (this.list) {
      this.formList = this.list;

      this.instantWinType = this.getValueByName('instantWinType');
      this.name = this.getValueByName('name');
      this.description = this.getValueByName('description');
      this.termsAndConditions = this.getValueByName('termsAndConditions');
      this.metadata = this.getValueByName('metadata');
      this.tags = this.getValueByName('tags');
      this.customFields = this.getValueByName('customFields');
    }
  },
  computed: {
    isScratchCardNotAllowed() {
      const notAllowedSpaces = ['tipico5g', 'tipico5g-staging', 'tipicogames-stg', 'cloudbet-staging'];
      const currentSpace = localStorage.getItem('zq-space-name');

      return notAllowedSpaces.includes(currentSpace);
    }
  },
  watch: {
    instantWinType(val) {
      const item = this.formList.find(item => item.key === 'instantWinType');
      item.value = val;
    },
    name(val) {
      const item = this.formList.find(item => item.key === 'name');
      item.value = val;
    },
    description(val) {
      const item = this.formList.find(item => item.key === 'description');
      item.value = val;
    },
    termsAndConditions(val) {
      const item = this.formList.find(item => item.key === 'termsAndConditions');
      item.value = val;
    },
    metadata(val) {
      const item = this.formList.find(item => item.key === 'metadata');
      item.value = val;
    },
    tags(val) {
      const item = this.formList.find(item => item.key === 'tags');
      item.value = val;
    },
    customFields(val) {
      const item = this.formList.find(item => item.key === 'customFields');
      item.value = val;
    },
    formList: {
      deep: true,
      handler() {
        this.obj = {};
        this.formList.forEach((x) => {
          if (!x.ignore) {
            if (
                x.type.toLowerCase() !== 'namekey'
                && x.type.toLowerCase() !== 'hostingoptions'
                && x.type.toLowerCase() !== 'rewardentitytype'
            ) {
              this.obj[x.key] = x.value;
            } else {
              this.obj = {
                ...this.obj,
                ...x.value,
              };
            }
          }
        });

        this.$emit('updated', this.obj);
      },
    },
    list: {
      deep: true,
      handler(n, o) {
        if (!isEqual(o, n)) {
          this.formList = this.list;
        }
      },
    },
  },
  methods: {
    getLabel(fieldName) {
      const foundObject = this.list.find(item => item.key === fieldName);

      return foundObject.required ? startCase(foundObject.label) + '*' : startCase(foundObject.label);
    },
    getValueByName(name) {
      return this.formList.find(item => item.key === name)?.value;
    },
    getTooltipByName(name) {
      return this.descriptions.settings[name]
    }
  }
};
</script>

<style scoped lang="scss">
.c-nxt-app__main-theme .multiselect.custom-multi-select.zq--multiselect {
  width: 100%;
}
.instant-win-settings {
  display: flex;
  flex-direction: column;

  .name-tag-wrapper {
    .instant-win-tag {
      .tag-field-wrapper {
        width: 100%;
        .tag-field {
          .tags-multi-select {
            width: 100% !important;
          }

        }
      }
    }
  }


  .text-area-fields-wrapper {
    .flex-column {
      .text-area-field {
        background: #00a6be;
      }
    }
  }

  .tags-field {
    width: 100%;
  }

  .form-group {
    width: 100%;
  }
}
.zq--form-row.row {
  width: 100%;
}
</style>
