<template>
  <div class="settings-icon">
    <!--  Header    -->
<!--    <LocalBreadcrumb-->
<!--      :isPageNumber="false"-->
<!--      :items="breadcrumbItems"-->
<!--      @setCurrentPage="setCurrentPage"-->
<!--      :toolTipText="descriptions.repositories"-->
<!--    />-->
    <!-- Table  Header  -->
    <div class="mr-0 mb-2 d-flex justify-content-between flex-wrap">
      <!--  SEARCH SECTION -->
      <ClSearch
        class="mb-2"
        :placeholder="$t('tableHeader.search')"
        @searchUpdate="searchData"
      />
      <!--  GO BACK    -->
      <div class="d-flex justify-content-end">
        <ClNavButton :label="$t('buttons.goBack')" @handler="goBack"/>
      </div>
    </div>
    <!--  Table  -->
    <CRow class="mr-0">
      <CCol col="12">
        <CCard class="zq-card-table">
          <CCardBody>
            <CDataTable
              id="reposTable"
              ref="repoTable"
              class="zq--table"
              :items="fileRepositories"
              :fields="fields"
              :column-filter="columnFilter"
              :items-per-page="itemsPerPage"
              :sorter="{ external: true }"
              :responsive="true"
              :border="isDefaultTheme"
              :hover="isDefaultTheme"
              :striped="isDefaultTheme"
              :loading="!ready"
              @pagination-change="itemsPerPageSelect"
              @page-change="paginationChange"
              @update:column-filter-value="handleColumnFilter"
              @update:sorter-value="handleSortBy"
            >
              <template #loading>
                <TableLoader/>
              </template>
              <!--       ID       -->
              <template #id="{ item }">
                <td @click="showPreview(item.id, item.name)">
                  <CLink>
                    {{ item.id }}
                  </CLink>
                </td>
              </template>
              <!--    Created      -->
              <template #created="{item}">
                <td>{{ dateFormate(item.created) }}</td>
              </template>
              <!--    NAME      -->
              <template #name="{item}">
                <td>{{ textShorten(item.name) }}</td>
              </template>
              <!--    DESCRIPTION      -->
              <template #description="{item}">
                <td>{{ textShorten(item.description) }}</td>
              </template>
              <!--    Metadata      -->
              <template #metadata="{item}">
                <td>{{ getMetaData(item.metadata) }}</td>
              </template>
              <!--      Tags        -->
              <template #tags="{item}">
                <td>
                  <span class="text-ellipsis overflow-hidden text-nowrap d-block">
                    {{ getTags(item.tags) }}
                  </span>
                </td>
              </template>
              <!--       Hosting Opts       -->
              <template #hostingOptions="{item}">
                <td><span>{{ item.indexFileName }}</span>
                  <span>{{ item.errorFileName }}</span>
                </td>
              </template>
            </CDataTable>
            <!-- Table Footer   -->
            <TableFooter
              :page="page"
              @updatePagenation="paginationChange"
              :pages="pages"
              :total="resultCount"
              :itemsPerPage="itemsPerPage"
              :disabled="!ready"
              @updateItemPerPage="itemsPerPageSelect"
            />
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
// import LocalBreadcrumb from '@/shared/components/LocalBreadcrumb';
import TableFooter from '@/components/table/Footer';
import TableLoader from "@/components/table/Loader";
import ClSearch from '@/shared/components/formComponents/ClSearch';
import {textShorten} from '@/utils/textUtils';
import {dateFormate} from '@/utils/dateFormate';

import {pageConfig} from '@/config';
import ClNavButton from "@/shared/components/formComponents/ClNavButton";

export default {
  components: {
    TableFooter,
    TableLoader,
    // LocalBreadcrumb,
    ClSearch,
    ClNavButton
  },
  props: {
    descriptions: {
      type: Object,
      default() {
        return {}
      }
    },
    backPath: String,
    breadcrumbItems: Array,
  },
  data() {
    return {
      columnFilter: false,
      ready: false,
      search: '',
      query: {},
      sortBy: pageConfig.sortBy,
      itemsPerPage: pageConfig.itemsPerPage,
      page: pageConfig.page,
      fields: [
        "id",
        "created",
        "name",
        "tags",
        "description",
      ]
    }
  },
  computed: {
    ...mapGetters('fileRepositories', [
      'fileRepositories',
      'pages',
      'loading',
      'resultCount',
      'originalFields',
      'success',
      'message',
    ]),
    ...mapGetters('theme', ['theme']),
    isDefaultTheme() {
      return this.theme === 'default'
    }
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('fileRepositories', [
      'handleGetFileRepositoriesByQuery',
    ]),
    initialize() {
      this.handleGetFileRepositoriesByQuery({
        queryRequest: {
          must: [],
          sortBy: [
            {
              queryField: 'created',
              order: 'Desc',
            },
          ],
          skip: 0,
          limit: 10,
        }
      })
        .then(() => {
          this.ready = true;
        });
    },
    textShorten(text) {
      return textShorten(text)
    },
    searchData(value) {
      this.search = value ? value : '';
      this.query = {name: this.search};
      if (this.search === '') {
        this.query = {};
      }
      this.handleGetFileRepositories([], null, null);
    },
    dateFormate(val) {
      return dateFormate(val);
    },
    goBack() {
      this.$emit('setCurrentPage', {page: 'selectIcon'});
    },
    setCurrentPage(e) {
      console.log('setCurrentPage e:', e)
      this.$emit('setCurrentPage', {page: e});
    },
    itemsPerPageSelect(val) {
      this.itemsPerPage = val;
      this.page = 1;
      this.handleGetFileRepositories([], null, null);
    },
    paginationChange(val) {
      this.ready = false;
      this.page = val;
      this.handleGetFileRepositoriesByQuery({
        queryRequest: {
          must: [],
          sortBy: [
            {
              queryField: 'created',
              order: 'Desc',
            },
          ],
          skip: (this.page - 1) * 10,
          limit: 10,
        }
      })
        .then(() => {
          this.ready = true;
        });
    },
    handleColumnFilter(e) {
      if (e.field && e.value) {
        this.query[e.field] = e.value.trim();
      } else {
        delete this.query[e.field];
      }
      this.handleGetFileRepositories([], null, null);
    },
    getTags(items) {
      let tags = '';
      if (items && items.length > 0)
        items.forEach((element, index) => {
          if (index === items.length - 1) {
            tags += element;
          } else tags += element + ', ';
        });
      return tags;
    },
    getMetaData(items) {
      let meta = '';
      const maxlength = 20;
      if (items && items.length > 0) {
        items.forEach(element => {
          meta += element.key + ': ' + element.value + '\n';
        });
      }
      return meta.length > maxlength
        ? meta.substring(0, maxlength - 3) + '...'
        : meta.substring(0, maxlength);
    },
    handleSortBy(e) {
      const sort = [
        {
          queryField: e.column,
          order: e.asc ? 'ASC' : 'Desc',
        },
      ];
      this.sortBy = sort;
      this.page = 1;
      this.handleGetFileRepositories([], null, null);
    },

    showPreview(id, name) {
      this.$emit('setCurrentPage', {page: 'files', id: id, name: name});
    },

    resetSearch() {
      this.searchData(null)
    }
  },
}
</script>

<style lang="scss">
.settings-icon {
  height: 100%;

  .search-container {
    position: relative;
    padding-right: 0;

    .search-clear {
      position: absolute;
      top: 6px;
      right: 3%;
      cursor: pointer;
    }
  }

  .custom-dropbox {
    .dropdown-menu {
      min-width: 80px;
      width: 95px !important;
    }
  }

  .search-reset {
    position: absolute;
    top: -5px;
    right: -9px;
  }

  .action-custom-button {
    width: 100%;
  }

  #reposTable {
    .table {
      td:first-child {
        &:after {
          left: 16px;
        }

        &:before {
          left: 16px;
        }
      }

      td:last-child {
        &:after {
          right: 16px;
        }

        &:before {
          right: 16px;
        }
      }
    }
  }
}
</style>