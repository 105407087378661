<template>
  <div class="files-popup">
    <div class="close-btn" @click="close">
      &#x2715;
    </div>
    <div class="popup-content">
      <!-- Search Bar -->
      <div class="search-container">
        <input type="text" v-model="search" placeholder="Search..." @input="searchData">
        <img
          src="@/assets/icons/search.svg"
          width="16"
          height="16"
          alt="search"
          class="search-icon"
        />
      </div>
      <div class="d-flex align-items-center justify-content-start">
        <div class="d-flex align-items-center go-back" @click="goBack">
          <img src="@/assets/icons/blue-back-arrow.svg" alt="<"><span>Repositories</span>
        </div>
      </div>
      <!-- List of Files -->
      <div class="files">
        <div
          v-for="(fileObject, index) in fileObjects"
          :key="index"
          class="file"
          @click="selectFileObject(fileObject)"
          :title="fileObject.name"
        >
          <img
            v-if="['image/png', 'image/jpeg', 'image/svg', 'image/svg+xml', 'image/webp'].includes(fileObject.mimeType)"
            :src=fileObject.uri
            alt=""
            class="file_icon"
          >
          <div class="file_name">{{ fileObject.name }}</div>
          <div
            class="file_id"
            :title="fileObject.id"
          >
            {{ fileObject.id }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'FilesPopup',
  props: {
    fileData: Object,
    backPath: String,
  },
  data() {
    return {
      search: '',
      sortBy: [
        {
          queryField: 'created',
          order: 'Desc',
        },
      ],
      fileObjects: []
    };
  },
  computed: {
    ...mapGetters('files', [
      'totalRecords',
      'pages',
      'loading',
      'resultCount',
      'success',
      'message'
    ]),
  },
  created() {
    this.initialize();
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjectsByQuery']),
    async initialize() {
      let limit = 20;
      let offset = 0;

      let totalRecords = 0;
      let fileObjects = [];

      do {
        let query = {
          queryRequest: {
            must: [
              {
                queryField: 'repositoryId',
                queryValues: [this.fileData.id],
              },
              {
                queryField: 'mimeType',
                queryValues: ['image/png', 'image/jpeg', 'image/svg', 'image/svg+xml', 'image/webp'],
              },
            ],
            sortBy: this.sortBy,
            skip: offset,
            limit: limit,
          }
        };

        const data = await this.handleGetFileObjectsByQuery(query);
        totalRecords = this.totalRecords;
        fileObjects = fileObjects.concat(data);

        offset += limit;

      } while (fileObjects.length < totalRecords);


      this.fileObjects = fileObjects;
      this.ready = true;
    },
    async searchData(e) {
      if (e.target.value.length > 0) {
        this.fileObjects = await this.handleGetFileObjectsByQuery({
          queryRequest: {
            must: [
              {
                queryField: 'repositoryId',
                queryValues: [this.fileData.id],
              },
              {
                queryField: 'mimeType',
                queryValues: ['inode/directory', 'image/png', 'image/jpeg', 'image/svg', 'image/svg+xml', 'image/webp'],
              }
            ],
            multiFields: [
              {
                queryFields: [],
                queryValue: e.target.value,
              },
            ],
            sortBy: this.sortBy,
            skip: 0,
            limit: 20,
          }
        });
      } else {
        await this.initialize();
      }
    },
    async selectFileObject(item) {
      this.$emit('addFile', { id: item.id, uri: item.uri });
    },

    goBack() {
      this.$emit('setCurrentPage', { page: this.backPath });
    },
    close() {
      this.$emit('setCurrentPage', { page: 'selectIcon' });
    }
  }
};
</script>

<style scoped lang="scss">
.files-popup {
  width: 690px;
  max-height: 380px;
  min-height: 380px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
  background: #FFFFFF;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  padding: 20px 0;

  .close-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 34px;
    height: 34px;
    top: -17px;
    right: -17px;
    z-index: 55;
    border-radius: 50%;
    border: 1px solid #b2b6c2;
    background-color: #fff;
    cursor: pointer;
  }

  .popup-content {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.search-container {
  width: 100%;
  padding: 10px;
  position: relative;

  .search-icon {
    position: absolute;
    top: 13px;
    right: 20px;
  }

  > input {
    width: inherit;
    border: 1px solid #D8DBE0;
    height: 34px;
    outline: none;
  }

  > input:focus {
    outline: none;
  }

}


.go-back {
  cursor: pointer;
  color: #007DFB;
  margin-top: 14px;
  font-size: 14px;
  font-weight: 500;

  > img {
    margin: 0 5px 0 10px;
  }
}

.files {
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  max-height: 280px;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #D8DBE0;
    border-radius: 4px;
  }
}

.file {
  padding: 5px 3px;
  cursor: pointer;
  width: 94px;
  height: 103px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
  margin: 10px 8px;

  .file_icon {
    height: 60px;
    width: 60px;
  }

  .file_name {
    width: inherit;
    height: 21px;
    font-size: 14px;
    color: #3C4B64;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    padding: 0 3px;
  }

  .file_id {
    width: inherit;
    height: 18px;
    font-size: 12px;
    color: #35404D;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
}

.file:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  transform: translateY(-5px);
}

.file {
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
</style>
