<template>
  <section>
    <div class="popup-wrapper">
      <SelectIcon
        @setCurrentPage="setPage"
        @deleteFile="deleteFile"
        :fileString="fileString"
        :label="label"
        :descriptions="descriptions"
        :iconKey="iconKey"
        :isColumn="isColumn"
        :descriptionKey="descriptionKey"
      />
      <Repositories
        v-if="currentPage === 'repositories'  && !isPopup"
        :back-path="'selectIcon'"
        @setCurrentPage="setPage"
        :breadcrumb-items="['Settings', 'Repositories']"
      />
      <RepositoriesPopup
        v-if="currentPage === 'repositories' && isPopup"
        :back-path="'selectIcon'"
        @setCurrentPage="setPage"
      />
      <Files
        v-if="currentPage === 'files' && !isPopup"
        :back-path="'repositories'"
        :fileData="fileData"
        @setCurrentPage="setPage"
        @addFile="addIcon"
        :breadcrumb-items="['Settings', 'Repositories', 'Files']"
      />
      <FilesPopup
        v-if="currentPage === 'files' && isPopup"
        :back-path="'repositories'"
        :fileData="fileData"
        @addFile="addIcon"
        @setCurrentPage="setPage"
      />
    </div>
  </section>
</template>

<script>
import SelectIcon from './SelectIcon';
import Repositories from './Repositories';
import Files from './Files';
import { mapActions } from 'vuex';
import RepositoriesPopup from '@/shared/components/CIIconField/RepositoriesPopup.vue';
import FilesPopup from '@/shared/components/CIIconField/FilesPopup.vue';

export default {
  name: "CIIconField",
  components: {
    FilesPopup,
    RepositoriesPopup,
    SelectIcon,
    Repositories,
    Files,
    // PreviewFile,
  },
  props: {
    label: {
      type: String,
      default: 'Icon'
    },
    iconKey: {
      type: String,
      default: 'icon'
    },
    descriptions: {
      type: Object,
      default() {
        return {};
      }
    },
    descriptionKey: {
      type: String,
      default: ''
    },
    value: String,
    isColumn: {
      type: Boolean,
      default: false
    },
    isPopup: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentPage: 'selectIcon',
      pageNumber: '1',
      fileData: {},
      fileString: null,
      fileId: null,
    }
  },
  created() {
    if (this.value) {
      this.getFile(this.value)
    }
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjects_item']),
    async getFile(id) {
      const file = await this.handleGetFileObjects_item({idArray: [id]})
      this.fileString = file[0].uri;
    },
    setPage(data) {
      if (data.page === 'files') {
        this.fileData.id = data.id;
        if (data.name) {
          this.fileData.name = data.name;
        }
      }
      if (data.page === 'previewFile') {
        this.fileData.id = data.data.id;
        this.fileData.repositoryId = data.data.repositoryId;
      }
      this.currentPage = data.page;
    },
    addIcon(i) {
      this.fileString = i.uri;
      this.fileId = i.id;
      this.currentPage = 'selectIcon';
      this.$emit('input', this.fileId);
    },
    deleteFile(fileId) {
      this.fileString = null;
      this.fileId = null;
      this.$emit('input', null);
      this.$emit('deleteFile'); // Instant wins
    }
  }
}
</script>

<style scoped>
.popup-wrapper {
  position: relative;
}
</style>
