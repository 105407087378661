<template>
  <div class="congratulation-block" >
    <canvas
      v-if="winningTileReward && localMessageSettings.isCelebrationAnimation || !winningTileReward && localMessageSettings.isSorryAnimation"
      ref="confettiCanvas"
      id="confetti"
    >

    </canvas>
    <CongratulationWindow
      class="congrats"
      @stopConfetti="stopConfetti"
      :winningTileReward="winningTileReward"
      :settingsData="settingsData"
      :messageSettings="localMessageSettings"
    />
  </div>
</template>

<script>
import confetti from 'canvas-confetti';
import CongratulationWindow from '@/shared/components/supportModels/instantWins/CongratulationWindow.vue';
import { mapActions } from 'vuex';

export default {
  name: 'CongratulationWithConfetti',
  components: { CongratulationWindow },
  props: {
    winningTileReward: Object,
    settingsData: Object,
    messageSettings: Object
  },
  data() {
    return {
      localMessageSettings: { ...this.messageSettings }
    }
  },
  mounted() {
    if (this.winningTileReward && this.localMessageSettings.isCelebrationAnimation || !this.winningTileReward && this.localMessageSettings.isSorryAnimation) {
      const canvas = this.$refs.confettiCanvas;
      canvas.width = 750;
      canvas.height = 650;
    }
  },
  created() {
    if (this.localMessageSettings) {
      if (this.localMessageSettings.celebrationImage) {
        this.fetchImage(this.localMessageSettings.celebrationImage, 'celebrationImage');
      }
      if (this.localMessageSettings.sorryImage) {
        this.fetchImage(this.localMessageSettings.sorryImage, 'sorryImage');
      }
    }

    if (
        this.winningTileReward && this.localMessageSettings.isCelebrationAnimation
        || !this.winningTileReward && this.localMessageSettings.isSorryAnimation
    ) this.startConfettiLoop();
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjects_item']),
    startConfettiLoop() {
      this.confettiLoop = setTimeout(() => {
        this.startConfetti(0.25, {
          spread: 26,
          startVelocity: 55,
        });
        this.startConfetti(0.2, {
          spread: 60,
        });
        this.startConfetti(0.35, {
          spread: 100,
          decay: 0.91,
          scalar: 0.8,
        });
        this.startConfetti(0.1, {
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2,
        });
        this.startConfetti(0.1, {
          spread: 120,
          startVelocity: 45,
        });
        this.startConfettiLoop();
      }, 1500);
    },
    startConfetti(particleRatio, opts) {
      const count = 200;
      const defaults = {
        origin: { x: 0.57, y: 0.7 },
        zIndex: 0,
      };

      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio)
      });
    },
    stopConfetti() {
      clearTimeout(this.confettiLoop);
      confetti.reset();
      this.$emit('stopCongratsWithConfetti')
    },
    async fetchImage(imageId, type) {
      try {
        const file = await this.handleGetFileObjects_item({ idArray: [imageId] });
        const uri = file && file.length > 0 ? file[0].uri : null;
        if (uri) {
          this.localMessageSettings[type] = uri;
        }
      } catch (error) {
        console.error('Error fetching image:', error);
      }
    },
  },

};
</script>

<style scoped lang="scss">
.congratulation-block {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .congrats {
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
  }

  #confetti {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 750px;
    height: 650px;
    border-radius: 20%;
  }
}
</style>