<template>
  <div class="congratulation-window" :class="{ 'shorter-height': !reward }">
    <span class="congratulation-window_title" v-html="congratulationMessage"></span>
    <span class="congratulation-window_subtitle" v-html="subtitle"></span>
    <div class="congratulation-card" v-if="reward" >
      <div class="congratulation-card_info-block">
        <span class="expires-in">
          Expires in <span class="expires-in_days">30 days</span>
        </span>
        <div class="reward-info">
          <span class="reward-value">{{ reward.rewardValue }}</span>
          <span class="reward-name">{{ reward.rewardName }}</span>
        </div>
      </div>
      <div class="congratulation-card_icon" v-if="congratulationImage">
        <img :src="congratulationImage" alt="congratulation image">
      </div>
    </div>
    <div class="congratulation-card_icon mb-2" v-else-if="!reward && congratulationImage">
      <img :src="congratulationImage" alt="congratulation image">
    </div>
    <div class="congratulation-window-actions" :class="reward ? 'justify-between' : 'justify-center'">
      <div v-if="reward" class="not-now-btn" @click="close">{{ 'Not now' }}</div>
      <div class="claim-reward-btn" @click="claim">{{ reward ? 'Claim reward' : 'Return' }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CongratulationWindow',
  data() {
    return {
      reward: null
    }
  },
  props: {
    winningTileReward: Object,
    settingsData: Object,
    messageSettings: Object
  },
  created() {
    if (this.winningTileReward) this.reward = this.winningTileReward;
  },
  computed: {
    congratulationMessage() {
      if (this.reward) {
        if (this.messageSettings && this.messageSettings.celebrationMessage.length) {
          const content = this.messageSettings.celebrationMessage;

          return typeof content === 'string' && content.includes('<p')
              ? content.replace(/<p/g, '<p style="margin-bottom: 0;"')
              : content;

        } else {
          return 'Congratulations on reward!';
        }
      } else {
        if (this.messageSettings && this.messageSettings.sorryMessage.length) {
          let content = this.messageSettings.sorryMessage;

          if (typeof content === 'string' && content.includes('<p')) {
            content = content.replace(/<p/g, '<p style="margin: 0;"');
          }

          if (typeof content === 'string' && content.includes('<h')) {
            content = content.replace(/<h1/g, '<h1 style="margin: 0;"');
            content = content.replace(/<h2/g, '<h2 style="margin: 0;"');
            content = content.replace(/<h3/g, '<h3 style="margin: 0;"');
            content = content.replace(/<h4/g, '<h4 style="margin: 0;"');
            content = content.replace(/<h5/g, '<h5 style="margin: 0;"');
            content = content.replace(/<h6/g, '<h6 style="margin: 0;"');
          }

          return content;

        } else {
          return "Didn't win this time!";
        }
      }
    },
    subtitle() {
      if (this.reward) {
        if (this.messageSettings && this.messageSettings.celebrationText.length) {
          const content = this.messageSettings.celebrationText;

          return typeof content === 'string' && content.includes('<p')
              ? content.replace(/<p/g, '<p style="margin-bottom: 0;"')
              : content;

        } else {
          return 'You earned a reward';
        }
      } else {
        if (this.messageSettings && this.messageSettings.sorryText.length) {
          const content = this.messageSettings.sorryText;

          return typeof content === 'string' && content.includes('<p')
              ? content.replace(/<p/g, '<p style="margin-bottom: 0;"')
              : content;

        } else {
          return 'Wishing you better luck in the future!';
        }
      }
    },
    congratulationImage() {
      if (this.reward) {
        return this.messageSettings && this.messageSettings.celebrationImage
            ? this.messageSettings.celebrationImage
            : null;
      } else {
        return this.messageSettings && this.messageSettings.sorryImage
            ? this.messageSettings.sorryImage
            : null;
      }
    }
  },
  methods: {
    claim() {
      this.$emit('stopConfetti')
    },
    close() {
      this.$emit('stopConfetti')
    }
  }
};
</script>


<style scoped lang="scss">
.congratulation-window.shorter-height {
  height: 250px;
}

.congratulation-window {
  width: 430px;
  height: 350px;
  padding: 20px 26px 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow:
      0 4px 6px 0 rgba(31, 41, 55, 0.05),
      0 3px 15px 0 rgba(31, 41, 55, 0.1);
  border-radius: 10px;
  color: #3C4B64;

  &_title {
    font-size: 22px;
  }

  &_subtitle {
    font-size: 18px;
    color: #282F37;
  }

  .congratulation-card {
    margin: 20px 0 30px;
    padding: 15px 20px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #007DFB;
    display: flex;
    justify-content: space-between;
    background: rgba(0, 125, 251, 0.05);

    &_info-block {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .expires-in {
        font-size: 14px;

        &_days {
          color: #007DFB;
        }
      }

      .reward-info {
        display: flex;
        flex-direction: column;

        .reward-value {
          font-size: 32px;
          font-weight: 600;
        }

        .reward-name {
          font-size: 14px;
          font-weight: 400;
        }
      }
    }

    &_icon {
      width: 116px;
      height: 116px;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #1C1146;

      > img {
        width: 80%;
        height: 80%;
      }
    }
  }

  .justify-between {
    justify-content: space-between;
  }

  .justify-center {
    justify-content: center;
  }

  .congratulation-window-actions {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: auto;

    .claim-reward-btn {
      border: 1px solid #007DFB;
      border-radius: 10px;
      color: #FFFFFF;
      background-color: #007DFB;
      width: 172px;
      height: 44px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .not-now-btn {
      border: 1px solid #282F37;
      border-radius: 10px;
      color: #282F37;
      width: 172px;
      height: 44px;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
</style>