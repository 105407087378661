<template>
  <div id="bar-chart-container" class="bar-chart-container"></div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: 'BarChart',
  props: {
    chartsData: Array,
    tilesData: {
      type: Array,
      required: true,
    },
    autoProbability: Boolean,
    instantWinType: Number
  },
  data() {
    return {
      colorScale: ['#8D0C71', '#304F69'],
    }
  },
  mounted() {
    this.createChart();
  },
  methods: {
    createChart() {
      const margin = { top: 20, right: 20, bottom: 20, left: 40 };
      const width = 400 - margin.left - margin.right;
      const height = 300 - margin.top - margin.bottom;

      const x = d3.scaleBand().range([0, width]).padding(0.1);
      const y = d3.scaleLinear().range([height, 0]);


      const svg = d3.select("#bar-chart-container")
          .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);

      x.domain(this.chartsData.map((d, i) => i + 1));
      y.domain([0, Math.ceil(d3.max(this.chartsData, (d) => d * 100)) ]);

      svg.selectAll(".bar")
          .data(this.chartsData)
          .enter()
          .append("rect")
          .attr("class", "bar")
          .attr("x", (d, i) => x(i+ 1))
          .attr("width", x.bandwidth())
          .attr("y", (d) => y(d * 100))
          .attr("height", (d) => {
            return d ? height - y(d * 100) : 0;
          })
          .style('fill', (d, i) => {
            return this.getSectionFill(i);
          });

      svg.selectAll('.text')
          .data(this.chartsData)
          .enter()
          .append('text')
          .attr('class', 'text')
          .attr('x', (d, i) => x(i + 1) + x.bandwidth() / 2)
          .attr('y', (d) => y(d * 100) - 5)
          .attr('text-anchor', 'middle')
          .text((d) => {
            const percentage = (d * 100).toFixed(1)
            return d > 0 ? percentage + '%' : '';
          })

      svg.append("g")
          .attr("transform", `translate(0,${height})`)
          .call(d3.axisBottom(x));

      svg.append("g")
          .call(d3.axisLeft(y));
    },
    getSectionFill(index) {
      return this.instantWinType === 2
          ? this.colorScale[index % this.colorScale.length]
          : this.tilesData[index].background
              ? this.tilesData[index].background
              : this.colorScale[index % this.colorScale.length] ;
    },
  },
};
</script>

<style scoped>
.bar-chart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

}
</style>