<template>
  <div>
    <CModal
      :show.sync="showModal"
      title="Reward creation"
      centered
      fade
      :dialogClass="'custom-modal'"
    >
      <template #header>
        <h5 class="modal-title">{{ 'Create reward' }}</h5>
        <button type="button" class="close" @click="closeByIcon">
          <span aria-hidden="true">&times;</span>
        </button>
      </template>
      <CreateReward
        v-if="showModal"
        :key="rerenderKey"
        @createReward="createReward"
        @cancelReward="cancelReward"
        :fileData="{page: 'reward creation', data: selectedReward}"
        :pageNumber="0"
        :isRewardRankField="isRewardRankField"
        :rewardData="selectedReward"
        :isNoTitle="true"
        :entityModel="model"
      />
      <template #footer-wrapper></template>
    </CModal>
  </div>
</template>

<script>
import CreateReward from '@/shared/components/supportModels/rewards/components/CreateReward.vue';

export default {
  name: 'CreateRewardModal',
  components: {
    CreateReward
  },
  props: {
    selectedReward: Object,
    isShowRewardModal: Boolean,
    isRewardRankField: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      model: 'Reward',
      showModal: false,
      formData: {
        customFields: {},
        issueLimit: null,
        rewardRank: 1,
        name: '',
        rewardValue: '',
        rewardType: '',
        rewardTypeId: '',
        description: '',
        delay: '',
        icon: '',
        period: '',
        pointInTime: null,
        tags: [],
        metadata: {},
        translations: [],
        addConstraints: []
      },
      rewardNameValidate: null,
      intFields: ['delay', 'period'],
      rewardValueValidate: null,
      rewardTypesOptions: [],
      rewardTypeValidate: null,
      rewardRankValidate: null,
      rewardDelayValidate: true,
      pointDate: '',
      pointInTimeStartDateValidate: null,
      invalidPointInTimeStartDateMessage: null,
      isToolbar: false,
      isPointInTime: false,
      isAcknowledgmentRequired: true,
      rerenderKey: 0
    };
  },

  watch: {
    isShowRewardModal(val) {
      this.showModal = val;
      this.rerenderKey++;
    },
  },
  methods: {
    async createReward(val) {
      this.$emit('updateRewardData', [val]);
    },
    cancelReward() {
      this.showModal = false;
      this.$emit('closeModal');
      this.$emit('cancelReward');
    },
    closeByIcon() {
      this.$emit('closeModal');
      this.modal = false;
    },
  }
};
</script>

<style scoped lang="scss">
::v-deep .modal-dialog {
  max-width: 840px;
  width: 100%;
}

::v-deep .modal-footer {
  display: none;
}

::v-deep .modal-body {
  background-color: #f1f2f4 !important;
}
</style>
