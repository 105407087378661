<template>
  <div class="instant-win-type-container">
    <div class="zq--form-row--label">
      <span class="tag-label-text">{{ label }}</span>
    </div>
    <div class="d-flex align-items-center">
      <div
        class="type-container"
        :style="{ width: computedWidth, height: computedHeight, opacity: isDisabled ? 0.5 : 1, cursor: isDisabled ? 'not-allowed' : 'pointer' }"
      >
        <span class="textLabel">Spinner</span>
      </div>
      <IconWithTooltip  :text="tooltip"/>
    </div>
  </div>

</template>

<script>
import IconWithTooltip from '@/shared/UI/IconWithTooltip.vue';
import SwitchButton from '@/shared/UI/SwitchButton.vue';

export default {
  name: 'ZqInstantWinTypeSpinner',
  components: { SwitchButton, IconWithTooltip },
  props: {
    label: String,
    tooltip: String,
    width: String,
    height: String,
    isDisabled: Boolean
  },
  data() {
    return {
      defaultWidth: '260px',
      defaultHeight: '46px'
    };
  },
  computed: {
    computedWidth() {
      return this.width ? this.width : this.defaultWidth;
    },
    computedHeight() {
      return this.height ? this.height : this.defaultHeight;
    }
  },
};
</script>

<style scoped lang="scss">
.type-container {
  position: relative;
  width: fit-content;
  height: 28px;
  border: 1px solid #8bb3ff;
  border-radius: 20px;
  background: #007dfb;
  font-weight: 600;
  color: white;
  cursor: pointer;
  margin: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.textLabel {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.type-container span {
  padding: 1px 6px;
  text-align: center;
  z-index: 1;
}
.type-container span {
  color: white;
  font-size: 14px;
  line-height: 23px;
}
</style>
