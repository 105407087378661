var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"settings-icon"},[_c('div',{staticClass:"mr-0 mb-2 d-flex justify-content-between flex-wrap"},[_c('ClSearch',{staticClass:"mb-2",attrs:{"placeholder":_vm.$t('tableHeader.search')},on:{"searchUpdate":_vm.searchData}}),_c('div',{staticClass:"d-flex justify-content-end"},[_c('ClNavButton',{attrs:{"label":_vm.$t('buttons.goBack')},on:{"handler":_vm.goBack}})],1)],1),_c('CRow',{staticClass:"mr-0"},[_c('CCol',{attrs:{"col":"12"}},[_c('CCard',{staticClass:"zq-card-table"},[_c('CCardBody',[_c('CDataTable',{ref:"repoTable",staticClass:"zq--table",attrs:{"id":"reposTable","items":_vm.fileRepositories,"fields":_vm.fields,"column-filter":_vm.columnFilter,"items-per-page":_vm.itemsPerPage,"sorter":{ external: true },"responsive":true,"border":_vm.isDefaultTheme,"hover":_vm.isDefaultTheme,"striped":_vm.isDefaultTheme,"loading":!_vm.ready},on:{"pagination-change":_vm.itemsPerPageSelect,"page-change":_vm.paginationChange,"update:column-filter-value":_vm.handleColumnFilter,"update:sorter-value":_vm.handleSortBy},scopedSlots:_vm._u([{key:"loading",fn:function(){return [_c('TableLoader')]},proxy:true},{key:"id",fn:function(ref){
var item = ref.item;
return [_c('td',{on:{"click":function($event){return _vm.showPreview(item.id, item.name)}}},[_c('CLink',[_vm._v(" "+_vm._s(item.id)+" ")])],1)]}},{key:"created",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.dateFormate(item.created)))])]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.textShorten(item.name)))])]}},{key:"description",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.textShorten(item.description)))])]}},{key:"metadata",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm.getMetaData(item.metadata)))])]}},{key:"tags",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',{staticClass:"text-ellipsis overflow-hidden text-nowrap d-block"},[_vm._v(" "+_vm._s(_vm.getTags(item.tags))+" ")])])]}},{key:"hostingOptions",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('span',[_vm._v(_vm._s(item.indexFileName))]),_c('span',[_vm._v(_vm._s(item.errorFileName))])])]}}])}),_c('TableFooter',{attrs:{"page":_vm.page,"pages":_vm.pages,"total":_vm.resultCount,"itemsPerPage":_vm.itemsPerPage,"disabled":!_vm.ready},on:{"updatePagenation":_vm.paginationChange,"updateItemPerPage":_vm.itemsPerPageSelect}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }