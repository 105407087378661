<template>
  <div id="pie-chart-container" class="pie-chart-container"></div>
</template>

<script>
import * as d3 from 'd3';
import { mapActions } from 'vuex';

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    chartsData: Array,
    width: {
      type: Number,
      default: 200,
    },
    height: {
      type: Number,
      default: 200,
    },
  },
  data() {
    return {
      colorScale: ['#8D0C71', '#304F69'],
      iconUris: [],
      defs: null
    }
  },
  mounted() {
    this.createPie()
  },
  computed: {
    dataWithGraySection() {
      const totalProbability = this.chartsData.reduce((sum, section) => sum + Number(section), 0)
      const graySectionProbability = 1 - totalProbability

      const dataCopy = this.chartsData.map(item => ({
        probability: item
      }))

      dataCopy.push({isDefault: true, probability: Number(graySectionProbability)})

      return dataCopy
    },
  },
  methods: {
    ...mapActions('files', ['handleGetFileObjects_item']),
    async createPie() {
      const size = 300;
      const fourth = size / 4;
      const half = size / 2;
      const labelOffset = fourth * 1.4;

      const container = d3.select('#pie-chart-container');

      const chart = container.append('svg')
          .attr('width', size + 70)
          .attr('height', size)
          .attr('viewBox', `${-half} ${-half} ${size} ${size}`);

      this.defs = chart.append('defs')

      const plotArea = chart.append('g')
          .attr('transform', `translate(${half}, ${half})`)
          .attr('transform', 'scale(1.2)');

      plotArea
          .append('circle')
          .attr('class', 'outer-border')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', fourth)
          .attr('fill', 'none')
          .attr('stroke-width', 12)
          .attr('stroke', '#2F0426');

      const pie = d3.pie()
          .sort(null)
          .value(d => {
            return d.probability;
          });

      const arcs = pie(this.dataWithGraySection);

      const arc = d3.arc()
          .innerRadius(0)
          .outerRadius(fourth);

      const arcLabel = d3.arc()
          .innerRadius(labelOffset)
          .outerRadius(labelOffset);

      const sectionGroups = plotArea.selectAll('.section')
          .data(arcs)
          .enter()
          .append('g')
          .attr('class', 'section');

      sectionGroups.append('path')
          .attr('fill', (d, i) => d.data.isDefault ? '#ccc' : this.getSectionFill(i))
          .attr('stroke', '#8D0C71')
          .attr('d', arc);

      sectionGroups
          .append('circle')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', 4)
          .attr('fill', '#EE3EC8') // center circle color
          .attr('stroke-width', 2)
          .attr('stroke', '#2F0426');

      sectionGroups.append('text')
          .style('text-anchor', 'middle')
          .style('alignment-baseline', 'middle')
          .style('font-size', '12px')
          .attr('transform', d => `translate(${arcLabel.centroid(d)})`)
          .attr('class', 'section-label')
          .attr('y', '-0.6em')
          .attr('x', d => {
            const middle = (this.chartsData.length - 1) / 2;

            if (!d.data.isDefault && d.index <= middle) {
              return 5;
            } else {
              return -5;
            }
          })
          .text((d, i) => {
            const text = d.data.text ? d.data.text : `Tile ${i + 1}`;
            const { probability, isDefault } = d.data;
            const isProbability = probability && probability !== 0 && probability !== '0' && !isDefault;
            return isProbability  ? text : '';
          });

      const sectionNumberArc = d3.arc()
          .innerRadius(35)
          .outerRadius(fourth);

      sectionGroups.append("text")
          .attr("transform", (d) => "translate(" + sectionNumberArc.centroid(d) + ")")
          .attr('startOffset', '50%')
          .attr('text-anchor', 'middle')
          .text((d, i) => {
            const { probability, isDefault } = d.data;
            const isProbability = probability && probability !== 0 && probability !== '0' && !isDefault;
            return isProbability  ? i + 1 : '';
          })
          .style("font-family", "arial")
          .style("fill", "white")
          .style("font-size", 12);

      sectionGroups.append('text')
          .style('text-anchor', 'middle')
          .style('alignment-baseline', 'middle')
          .style('font-size', '12px')
          .attr('transform', d => `translate(${arcLabel.centroid(d)})`)
          .attr('class', 'section-label')
          .attr('y', '1em')
          .attr('x', d => {
            const middle = (this.chartsData.length - 1) / 2;

            if (!d.data.isDefault && d.index <= middle) {
              return 5;
            } else {
              return -5;
            }
          })
          .text(d => {
            const percentage = (d.data.probability * 100).toFixed(1)
            const { probability, isDefault } = d.data;
            const isProbability = probability && probability !== 0 && probability !== '0' && !isDefault;

            return isProbability  ? `${percentage}%` : ''
          });


      const lineArc = d3.arc()
          .innerRadius(60)
          .outerRadius(fourth);

      sectionGroups.append('line')
          .style('stroke', '#0F1921')
          .attr('x1', (d) => {
            return lineArc.centroid(d)[0]
          })
          .attr('y1', (d) => {
            return lineArc.centroid(d)[1]
          })
          .attr('x2', (d) => {
            const labelPos = arcLabel.centroid(d);
            return labelPos[0] > 0 ? labelPos[0] - 15 : labelPos[0] + 15;
          })
          .attr('y2', (d) => {
            const labelPos = arcLabel.centroid(d);
            return labelPos[1];
          })
          .attr('visibility', (d) => {
            const { probability, isDefault } = d.data;
            const isProbability = probability && probability !== 0 && probability !== '0' && !isDefault;

            return isProbability  ? 'visible' : 'hidden'
          });

      sectionGroups.append('line')
          .style('stroke', '#0F1921')
          .attr('x1', (d) => {
            const labelPos = arcLabel.centroid(d);
            return labelPos[0] > 0 ? labelPos[0] - 15 : labelPos[0] + 15;
          })
          .attr('y1', (d) => {
            const labelPos = arcLabel.centroid(d);
            return labelPos[1]
          })
          .attr('x2', (d) => {
            const labelPos = arcLabel.centroid(d);
            return labelPos[0] > 0 ? labelPos[0] + 15 : labelPos[0] - 15;
          })
          .attr('y2', (d) => {
            const labelPos = arcLabel.centroid(d);
            return labelPos[1];
          })
          .attr('visibility', (d) => {
            const { probability, isDefault } = d.data;
            const isProbability = probability && probability !== 0 && probability !== '0' && !isDefault;

            return isProbability  ? 'visible' : 'hidden'
          });
    },
    getSectionFill(index) {
      if (this.data[index].background) {
        return this.data[index].background;
      } else {
        return this.colorScale[index % this.colorScale.length];
      }
    },
  }
};
</script>
<style scoped>
.pie-chart-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>