<template>
  <div class="instant-win-type-container">
    <div class="zq--form-row--label">
      <span class="tag-label-text">{{ label }}</span>
    </div>
    <div class="d-flex align-items-center">
      <SwitchButton
        :isChecked="value === 2"
        :textLabelOn="'Spinner'"
        :textLabelOff="'Scratchcard'"
        name="instantWinType"
        @toggle="toggleSelection"
        :height="'48px'"
        :width="'400px'"
        :isDisabled="isDisabled"
      />
      <IconWithTooltip  :text="tooltip"/>
    </div>
  </div>
</template>

<script>
import SwitchButton from '@/shared/UI/SwitchButton.vue';
import IconWithTooltip from '@/shared/UI/IconWithTooltip.vue';

export default {
  name: 'ZqInstantWinType',
  components: { IconWithTooltip, SwitchButton },
  props: {
    value: [String, Number],
    label: String,
    tooltip: String,
    isDisabled: Boolean
  },
  data() {
    return {
      selectedType: null,
    }
  },
  methods: {
    toggleSelection(isChecked) {
      const selectedValue = isChecked ? 2 : 1;
      this.$emit('input', selectedValue);
    },
  }
};
</script>

<style scoped lang="scss">
.instant-win-type-container {
  display: flex;
  flex-direction: column;
}
</style>